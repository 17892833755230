import React from "react";
import "../styles/Footer.css";
import { Icon } from "@iconify/react";
import { AiFillStar } from "react-icons/ai";
import download1 from "../assets/images/DownloadApp/1.png";
import download2 from "../assets/images/DownloadApp/2.png";
import download3 from "../assets/images/DownloadApp/3.png";
import sibche from "../assets/images/Sibche.png";
import anarduni from "../assets/images/anarduni.png";
import sibapp from "../assets/images/sibapp.png";

function Footer() {
  return (
    <div className="footer-component">
      <div className="footer">
        <div className="footer-divs">
          <h4>اطلاعات تماس</h4>
          <div style={{ margin: "12px 0" }} className="footer-adress">
            <div className="phones">
              <span
                style={{
                  width: "100%",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                دفتر مرکزی تهران : عباس آباد ، خیابان بهشتی ، بعد از چهار راه
                اندیشه ، ساختمان بهشت ، طبقه چهار
              </span>
              <span
                style={{
                  width: "100%",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                دفتر مرکزی تبریز : خیابان فلسطین ، جنب درمانگاه کودکان ، پلاک
                1/336
              </span>
            </div>
            <div className="phones">
              <span>04132801930 - 04132840226</span>
              <span>04132840954 - 04132840955</span>
            </div>
          </div>
        </div>
        <div className="footer-divs">
          <h4>دسترسی سریع</h4>
          <div style={{ margin: "12px 0" }} className="links">
            <a href="http://www.shop.rabetettehadco.ir/">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>فروشگاه</span>
            </a>
            <a href="https://blog.rabetettehadco.ir/">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>وبلاگ</span>
            </a>
            <a href="http://www.shop.rabetettehadco.ir/warranty">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>پیگیری گارانتی</span>
            </a>
            <a href="http://www.shop.rabetettehadco.ir/instagram">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>قرعه کشی</span>
            </a>
            <a href="http://www.shop.rabetettehadco.ir/agentForm">
              <Icon icon="ci:dot-04-l" />
              <span style={{ marginRight: "12px" }}>نمایندگان</span>
            </a>
          </div>
        </div>
        <div className="footer-divs">
          <h4>درباره ما</h4>
          <div
            style={{
              margin: "12px 0",
              display: "flex",
              alignItems: "",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                color: "#2B3467",
              }}
            >
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <AiFillStar style={{ color: "#EDBB11", paddingLeft: "8px" }} />
                اتحاد نامی آشنا و مطمعن در صنایع الکتریک ایران
              </span>
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <AiFillStar style={{ color: "#EDBB11", paddingLeft: "8px" }} />
                اتحاد نگین درخشان در صنایع برق و الکترونیک ایران
              </span>
              <span
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <AiFillStar style={{ color: "#EDBB11", paddingLeft: "8px" }} />
                کیفیت نیز نوعی صداقت است
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="downloadApp">
        <a
          className="downloadAppButton"
          href="http://www.shop.rabetettehadco.ir/pwa"
        >
          <img src={download1} alt="downloadApp" />
        </a>
        <a
          className="downloadAppButton"
          href="https://play.google.com/store/apps/details?id=com.rabetettehad&gl=GB"
        >
          <img src={download2} alt="downloadApp" />
        </a>
        <a
          className="downloadAppButton"
          href="https://cafebazaar.ir/app/com.rabetettehad"
        >
          <img src={download3} alt="downloadApp" />
        </a>

        {/* <a className="downloadAppButton" href="#">
          <img src={sibche} alt="downloadApp" />
        </a>
        <a className="downloadAppButton" href="#">
          <img src={sibapp} alt="downloadApp" style={{width :"145px"}} />
        </a>
        <a className="downloadAppButton" href="#">
          <img src={anarduni} alt="downloadApp" />
        </a> */}
      </div>
      <div className="footer-bottom-creator">
        <span>
          {" "}
          تمامی حقوق محفوظ و متعلق به{" "}
          <span style={{ color: "rgb(255, 114, 33)" }}> رابط اتحاد </span>میباشد
          | طراحی و توسعه توسط{" "}
          <a
            style={{ textDecoration: "none", color: "#ffffff" }}
            href="https://platinco.ir/"
          >
            {" "}
            پلاتین{" "}
          </a>{" "}
        </span>
      </div>
    </div>
  );
}

export default Footer;
