import "./App.css";
import Body from "./components/Body";
import Header from "./components/Header";
import VideoSlider from "./components/VideoSlider";
import Footer from "./components/Footer";
import React, { useState } from "react";
import Lottie from "react-lottie";
import loaderAnimation from "./assets/images/animation/loader.json";
import Gallery from "./components/Gallery";
import GalleryImage from "./components/Gallery";
import { Routes, Route } from "react-router-dom";
import AllCom from "./components/AllCom";

function App() {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {loading === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      ) : (
        <div className="App">
          <Routes>
            <Route path="/gallery" element={<GalleryImage />} />
            <Route path="/" element={<AllCom />} />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
