import React from "react";
import Logo from "../assets/images/logo.png";
import "../styles/Header.css";
import { Icon } from "@iconify/react";
import "react-hamburger-menus/dist/style.css";
import Menu from "./Menu";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="Header-component">
      <div className="header-div">
        <img src={Logo} alt="Rabet Etehad Logo" style={{ width: "160px" }} />
        <div className="header-menu">
          <ul>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.rabetettehadco.ir/"
              >
                صفحه اصلی
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.shop.rabetettehadco.ir/aboutUs"
              >
                درباره ما
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.shop.rabetettehadco.ir/"
              >
                فروشگاه
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.shop.rabetettehadco.ir/agentForm"
              >
                نمایندگان
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://blog.rabetettehadco.ir/"
              >
                وبلاگ
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.shop.rabetettehadco.ir/contactUs"
              >
                تماس با ما
              </a>
            </li>
            <li>
            <Link
              
              to="/gallery"
            >
              گالری
            </Link>
            </li>
          </ul>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://en.rabetettehadco.ir/"
          className="header-btn"
        >
          <Icon
            style={{ fontSize: "20px", marginLeft: "8px" }}
            icon="twemoji:flag-united-states"
          />
          English
        </a>
      </div>
      <Menu />
    </div>
  );
}

export default Header;
