import React from "react";
import { GhostNavbar } from "react-hamburger-menus";
import { Icon } from "@iconify/react";
import "../styles/Menu.css";
import { Link } from "react-router-dom";

function Menu() {
  return (
    <div className="menu-button">
      <GhostNavbar
        styles={{
          fontColor: "#ffffff",
          position: "fixed",
          fontHoverColor: "black",
          listHoverColor: ["transparent", "#ffffff"],
          floatButtonX: 87,
          floatButtonY: 15,
          fontSize : "17px",
          navigationButton: {
            borderRadius: "5px",
            width: "50px",
            top: "10px !important",
            left: "10px ",
            margin: "-30px 0" 
            // backgroundColor: "transparent",
          },
          navigationBackground: {
            backgroundColor: "#2b3467",
            margin : "-6%"
          },
          iconColor: "#ffffff",
        }}
      >
        <ul
          style={{
            fontFamily: 'font-family: "Lato", sans-serif',
            color: "#ffffff",
            fontSize : "8px"
          }}
        >
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="http://www.rabetettehadco.ir/"
            >
              صفحه اصلی
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.shop.rabetettehadco.ir/aboutUs"
            >
              درباره ما
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="http://www.shop.rabetettehadco.ir/"
            >
              فروشگاه
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="http://www.shop.rabetettehadco.ir/agentForm"
            >
              نمایندگان
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://blog.rabetettehadco.ir/"
            >
              وبلاگ
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.shop.rabetettehadco.ir/contactUs"
            >
              تماس با ما
            </a>
          </li>
          <li>
            <Link to="/gallery">گالری</Link>
          </li>
          <button
            style={{
              fontSize: "18px",
              backgroundColor: "transparent",
              border: "2px solid #ffffff",
              color: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 36px",
              borderRadius: "12px",
            }}
          >
            <Icon
              style={{ fontSize: "20px", marginLeft: "8px" }}
              icon="twemoji:flag-united-states"
            />
            English
          </button>
        </ul>
      </GhostNavbar>
    </div>
  );
}

export default Menu;
