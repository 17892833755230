import React, { useState } from "react";
import "../styles/Body.css";
import Logo from "../assets/images/logo.png";
import Catalog from "../assets/images/Cables/catalog.png";
import { AiFillStar } from "react-icons/ai";
import AboutVideo from "../assets/videos/vid1.mp4";
import Fade from "react-reveal/Fade";
import test from "../assets/images/test.jpg";
import lighting from "../assets/images/Cables/Lighting.jpg";
import microImage from "../assets/images/Cables/black.jpg";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillPhone } from "react-icons/ai";
import partlogo1 from "../assets/images/Brands/1.png";
import partlogorabet from "../assets/images/Brands/2.png";
import partlogo3 from "../assets/images/Brands/3.png";
import partlogo4 from "../assets/images/Brands/5.png";
import logo4 from "../assets/images/Brands/4.png";

function Body() {
  const [lightining, setLightining] = useState(false);
  const [micro, setMicro] = useState(true);
  const [typec, setTypec] = useState(false);

  const lightiningFn = () => {
    if (lightining === false) {
      setLightining(true);
      setTypec(false);
      setMicro(false);
    }
  };
  const typecFn = () => {
    if (typec === false) {
      setTypec(true);
      setMicro(false);
      setLightining(false);
    }
  };
  const microFn = () => {
    if (micro === false) {
      setMicro(true);
      setTypec(false);
      setLightining(false);
    }
  };

  return (
    <div className="Body">
      <div className="about-rabet">
        <div className="about-right">
          <Fade right>
            <h2
            className="about-top-title"
              style={{
                borderBottom: "2px solid",
                display: "inline-block",
                paddingBottom: "8px",
                color: "#2B3467",
              }}
            >
              شرکت بهساز رابط اتحاد
            </h2>
            <Fade right>
              <div style={{ textAlign: "justify", textJustify: "auto" }}>
                این شرکت بعنوان واحد جدید گروه تولیدی صنعتی اتحاد در سال1399 و
                با هدف تولید بند رابط های الکتریکی و الکترونیکی تاسیس و آغاز
                بکارنمود.با توجه به نیاز و مصرف فراوان و عمومی کشور به بند رابط
                ها و کابل شارژر های موبایل-پرینتر-تبلت-کامپیوتر- لوازم صوتی و
                تصویری و محصولات همگن و علم به توان تولید داخلی این محصولات،شرکت
                بهساز رابط اتحاد با استعانت از ایزدمتعال و با تکیه به توان
                صنعتگران فنی مجموعه خود و پشتوانه30 سال تولید مستمر و حضور موفق
                در بازار مصرفی بعنوان اولین تولیدکننده داخلی، محصولات خود را با
                تولید کابل شارژر موبایل های نوع type-c -Iphone-Micro
                -پرینتر-کامپیوتر و لپ تاپ آغاز نمود است. شرکت بهساز رابط اتحاد
                با اخذ مجوزهای لازم جهت تاسیس و بهره برداری از اداره صنایع و
                معادن کشور تولیدات خود را مطابق با نظام نامه کیفیت مجموعه تولیدی
                صنعتی اتحاد آغاز و در مسیر بهره گیری از استانداردهای جهانی،
                تاییدیه کیفیت مجموعه تولیدی کیفیت محصولات تولیدی و Ceرا اخذ
                نموده است.
              </div>
            </Fade>
            <div className="about-bottom" style={{ display: "flex", justifyContent: "space-between" }}>
              <div
              className="about-bottom-child"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "24px 0",
                  color: "#2B3467",
                }}
              >
                <span className="about-spans">
                  <AiFillStar
                    style={{ color: "#EDBB11", paddingLeft: "8px" }}
                  />
                  اتحاد نامی آشنا و مطمعن در صنایع الکتریک ایران
                </span>
                <span className="about-spans">
                  <AiFillStar
                    style={{ color: "#EDBB11", paddingLeft: "8px" }}
                  />
                  اتحاد نگین درخشان در صنایع برق و الکترونیک ایران
                </span>
                <span className="about-spans">
                  <AiFillStar
                    style={{ color: "#EDBB11", paddingLeft: "8px" }}
                  />
                  کیفیت نیز نوعی صداقت است
                </span>
              </div>
              <img className="about-logo" src={Logo} alt="Rabet Logo" />
            </div>
          </Fade>
        </div>
        <Fade left>
          <video className="about-vid" controls src={AboutVideo}></video>
        </Fade>
      </div>
      <div className="RabetCatalog">
        <div className="catalogtext">
          <div className="text">
            <h2>دانلود کاتالوگ</h2>
            <span className="textdis">
              از این بخش میتوانید کاتالوگ شرکت بهساز رابط اتحاد را دانلود
              بفرمایید که شامل معرفی محصولات معرفی شرکت راه های ارتباطی و ... می
              باشد
            </span>
            <Fade bottom>
              <div className="btncnter">
                <a
                  href="https://rabetettehadco.ir/Download/catalog.pdf"
                  className="catalogdownload"
                  target="_blank"
                  rel="noreferrer"
                >
                  دریافت کاتالوگ
                </a>
              </div>
            </Fade>
          </div>
        </div>
        <div className="catalogimg">
          <Fade left>
            <img className="catalogimgsize" src={Catalog} alt="" />
          </Fade>
        </div>
      </div>
      <div className="products">
        <div className="product_details">
          {lightining === true ? (
            <Fade right>
              <h2 className="product-title" style={{borderBottom : "2px solid black" , padding : "12px 0" , fontSize : "26px"}}>کابل شارژ لایتنینگ</h2>
              <div style={{ textAlign: "justify", textJustify: "auto" , fontSize : "16px"}}>
                کابل شارژر لایتنینگ به‌طور اختصاصی توسط اپل طراحی و ساخته شده
                است. شارژرهای لایتنینگ کانکتورهای داک ۳۰ پینی ابتدایی گوشی‌های
                آیفون ۳ یا آیفون ۴ را جایگزین کردند.
              </div>
            </Fade>
          ) : micro === true ? (
            <Fade right>
              <h2 className="product-title" style={{borderBottom : "2px solid black" , padding : "12px 0" , fontSize : "26px"}}>کابل شارژ میکرو یو اس بی</h2>
              <div style={{ textAlign: "justify", textJustify: "auto" , fontSize : "16px"}}>
                شارژرهای میکرو یو اس بی یکی از متداول‌ترین انواع شارژر موبایل
                فروخته شده در دنیا هستند. در حقیقت، برندهایی مانند سامسونگ و ال
                جی تقریبا این شارژرها را به مدل استاندارد شارژرهای موبایل در
                دنیا تبدیل کرده اند.
              </div>
            </Fade>
          ) : typec === true ? (
            <Fade right>
              <h2 className="product-title" style={{borderBottom : "2px solid black" , padding : "12px 0" , fontSize : "26px"}}>کابل شارژ یو اس بی تایپ C</h2>
              <div style={{ textAlign: "justify", textJustify: "auto" , fontSize : "16px"}}>
                شارژرهای یو اس بی تایپ C نسبتا به‌تازگی وارد دنیای پورت‌های
                شارژر شدند. فقط چند سال از عمر آن‌ها می‌گذرد و بهترین جایگزین
                برای انواع کابل های شارژ رایج با پورت‌هایی مانند A یا B هستند.
              </div>
            </Fade>
          ) : null}
          <Fade right>
            <div className="products-buttons-div">
              <button
                className={lightining ? "product_btn_enable" : "product_btn"}
                onClick={lightiningFn}
              >
                کابل Lightining
              </button>
              <button
                className={typec ? "product_btn_enable" : "product_btn"}
                onClick={typecFn}
              >
                کابل Type C
              </button>
              <button
                className={micro ? "product_btn_enable" : "product_btn"}
                onClick={microFn}
              >
                کابل Micro
              </button>
            </div>
          </Fade>
        </div>
        <div className="product_image">
          {lightining === true ? (
            <Fade left>
              <img className="cable-img" src={lighting} alt="Lightining" />
            </Fade>
          ) : micro === true ? (
            <Fade left>
              <img className="cable-img" src={microImage} alt="Lightining" />
            </Fade>
          ) : typec === true ? (
            <Fade left>
              <img className="cable-img" src={test} alt="Lightining" />
            </Fade>
          ) : null}
        </div>
      </div>
      <div className="contact-us">
        <div className="contact-box">
          <div className="contact-about">
            <span className="about-title">تماس با ما</span>
            <div className="adress">
              <HiLocationMarker className="Icons" />
              <div className="phones">
                <span>
                  دفتر مرکزی تهران : عباس آباد ، خیابان بهشتی ، بعد از چهار راه
                  اندیشه ، ساختمان بهشت طبقه چهار
                </span>
                <span>
                  دفتر مرکزی تبریز : خیابان فلسطین ، جنب درمانگاه کودکان ، پلاک
                  1/336
                </span>
              </div>
            </div>
            <div className="adress">
              <AiFillPhone className="Icons" />
              <div className="phones">
                <span>04132801930 - 04132840226</span>
                <span>04132840954 - 04132840955</span>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form id="form">
              <div className="inputs1">
                <input
                  className="form-name"
                  placeholder="نام"
                  type="text"
                  required
                />
                <input
                  className="form-email"
                  placeholder="ایمیل"
                  type="email"
                  required
                />
              </div>
              <div className="inputs">
                <input
                  className="form-subject"
                  placeholder="موضوع"
                  type="text"
                  required
                />
                <input
                  className="form-msg"
                  placeholder="متن پیام ..."
                  type="text"
                  required
                />
              </div>
              <div className="submit-div">
                <button
                  type="submit"
                  // onClick={sendMessage}
                  className="submit-form-btn"
                >
                  ارسال پیام
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="partner-div">
        <h2 style={{ color : "#ffffff" , fontWeight: "500" , fontSize : "36px"}}>برند های زیر مجموعه</h2>
        <div className="logos-partners">
        <Fade right>
          <a href="http://etlink.ir/">
            <img className="brand-logo" src={partlogo1} alt="" />
          </a>
          <a href="http://rabetettehadco.ir/">
            <img className="brand-logo" src={partlogorabet} alt="" />
          </a>
        </Fade>
        <Fade bottom>
          <a href="http://masterlinkplus.com/">
            <img className="brand-logo" src={partlogo3} alt="" />
          </a>
        </Fade>
        <Fade left>
          <a href="https://www.shop.en.rabetettehadco.ir/">
            <img className="brand-logo" src={logo4} alt="" />
          </a>
          <a href="http://we-netplus.com/">
            <img className="brand-logo" src={partlogo4} alt="" />
          </a>
        </Fade>
      </div>
      </div>
    </div>
  );
}

export default Body;
